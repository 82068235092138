exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-buyers-guide-js": () => import("./../../../src/pages/information/buyers-guide.js" /* webpackChunkName: "component---src-pages-information-buyers-guide-js" */),
  "component---src-pages-information-sellers-guide-js": () => import("./../../../src/pages/information/sellers-guide.js" /* webpackChunkName: "component---src-pages-information-sellers-guide-js" */),
  "component---src-pages-information-tenants-guide-js": () => import("./../../../src/pages/information/tenants-guide.js" /* webpackChunkName: "component---src-pages-information-tenants-guide-js" */),
  "component---src-pages-investor-visa-js": () => import("./../../../src/pages/investor-visa.js" /* webpackChunkName: "component---src-pages-investor-visa-js" */),
  "component---src-pages-new-projects-js": () => import("./../../../src/pages/new-projects.js" /* webpackChunkName: "component---src-pages-new-projects-js" */),
  "component---src-pages-property-js": () => import("./../../../src/pages/property.js" /* webpackChunkName: "component---src-pages-property-js" */),
  "component---src-pages-property-management-js": () => import("./../../../src/pages/property-management.js" /* webpackChunkName: "component---src-pages-property-management-js" */),
  "component---src-pages-property-property-community-index-js": () => import("./../../../src/pages/property/{property.community}/index.js" /* webpackChunkName: "component---src-pages-property-property-community-index-js" */),
  "component---src-pages-property-property-community-property-sub-community-index-js": () => import("./../../../src/pages/property/{property.community}/{property.sub_community}/index.js" /* webpackChunkName: "component---src-pages-property-property-community-property-sub-community-index-js" */),
  "component---src-templates-property-template-js": () => import("./../../../src/templates/property-template.js" /* webpackChunkName: "component---src-templates-property-template-js" */),
  "slice---src-layout-footer-js": () => import("./../../../src/layout/Footer.js" /* webpackChunkName: "slice---src-layout-footer-js" */),
  "slice---src-layout-header-js": () => import("./../../../src/layout/Header.js" /* webpackChunkName: "slice---src-layout-header-js" */)
}

